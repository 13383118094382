import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const JulyPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">
                July started and we kept doing things
            </h4>
            <p>We saw the 4th of July fireworks together and went to parties.</p>
            <div className="pic-row margin-top-2x">
                {[701, 8, 9].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">We went to the Arboretum</h4>
            <div className="pic-row margin-top-1x">
                {[10, 13].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[11, 12, 14].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">
                I introduced us to scooters
            </h4>
            <p>But we were still walking all the way down to the mall after nights out</p>
            <div className="pic-row margin-top-2x">
                {[15].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">I took you out to Harpers Ferry!</h4>
            <p>Our first adventure together!!</p>
            <div className="pic-row margin-top-2x">
                {[17].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[16, 18].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[19, 20].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const JulyPage = withWrapper(JulyPageInner);