import React, { SyntheticEvent } from "react";
import useImage from "./useImage";

type Props = {
    fileName: string;
    alt: string;
    className?: string;
    onLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
};

const Image = ({ fileName, alt, className, ...rest }: Props) => {
    const { loading, error, image } = useImage(fileName);

    if (error) return <span>{alt}</span>;

    if (loading) return <span>loading</span>;

    return <img className={className} src={image} alt={alt} {...rest} />;
};

export default Image;
