import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const NovemberPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">November</h4>
            <p>
                We went to the gym, and finally got out to Gravelly Point just us two. Also, this was the longest my
                beard had ever gotten!
            </p>
            <div className="pic-row margin-top-2x">
                {[68, 70, 72, 71].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[69].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">We went back to Great Falls</h4>
            <div className="pic-row margin-top-2x">
                {[722].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[721, 73, 74, 723].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <p>
                You spent my birthday with me! We had dinner with my friends, and we ate cake on the rooftop. I had a
                birthday party, i dont have those. I brought you home for Thanksgiving, and you almost won at Catan! I
                love those drawings you made. You met my brother, and we went out to Kiki.
            </p>
            <div className="pic-row margin-top-2x pic-row-center">
                {[724, 75].map((num) => (
                    <PictureItem key={num} className="limit-width-320" src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const NovemberPage = withWrapper(NovemberPageInner);
