import "./RegisterAccount.scss"

import React, { useState } from "react";
import  { SubmitHandler, useForm } from "react-hook-form";
import Container, { Sizes } from "../../core/Container";
import { zodResolver} from "@hookform/resolvers/zod";
import Loader from '../../core/Loader'
import { config } from "../../config";

import { z } from 'zod';
import { HttpBaseHeaders, HttpMethod } from "../../configApi";

export const userSchema = z.object({
  username: z
    .string()
    .min(3)
    .max(20)
    .regex(new RegExp("^(?!.*?([_.-]).*\\1)[A-Za-z0-9.-_]+$"), "Please use numbers, letters, underscores, or periods"),
  password: z
    .string()
    .min(8)
    .max(20)
});

export type UserSchemaType = z.infer<typeof userSchema>;

type Props = {
    login: (username: string ) => void;
}

type RegisterAccountResponse = {
    data?: {
        username: string;
    }
    error?: {
        message: string;
    }
}

export const RegisterAccount = (props: Props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [registerError, setRegisterError] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<UserSchemaType>({ resolver: zodResolver(userSchema) });

    const onSubmit: SubmitHandler<UserSchemaType> = async (data) => {
        setIsLoading(true);
        setRegisterError("");

        const resp = await fetch(config.API_DOMAIN + "auth/register", {
            method: HttpMethod.POST,
            headers: HttpBaseHeaders,
            body: JSON.stringify(data),
            credentials: 'include'
        })

        const responseData: RegisterAccountResponse = await resp.json();

        if (resp.status == 200) {
            setIsLoading(false);
            props.login(responseData.data!.username);
        } else {
            setIsLoading(false);
            setRegisterError(responseData.error!.message);
        }
    }
   
    return (
        <div className="bg">
            <Container size={Sizes.xs} >
                <div className="flex-center">
                    <div className="auth-box">
                        <form className="form" onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <h2 className="title margin-top-0x">Create an account</h2>
                            </div>
                            <div className="margin-bottom-1x">
                                <label className="label-default" htmlFor="uid_1">
                                    Username
                                    {errors.username && <span className="error-text"> - {errors.username.message}</span>}
                                </label>
                                <input 
                                    id="uid_1" 
                                    type="text" 
                                    className="input-text"
                                    {...register("username")}/>
                            </div>
                            <div className="margin-bottom-1x">
                                <label className="label-default" htmlFor="uid_2">
                                    Password
                                    {errors.password && <span className="error-text"> - {errors.password.message}</span>}
                                </label>
                                <input 
                                    id="uid_2"
                                    type="password" 
                                    className="input-text"
                                    {...register("password")}/>
                            </div>
                            <button type="submit" className="button-submit" disabled={isLoading}>
                                {!isLoading ? "Continue" : <Loader className="spinner" />}
                            </button>
                            {registerError && <p className="error-text">{registerError}</p>}
                        </form>
                    </div>
                </div>
            </Container>
        </div>
    );
};



