import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

export const JanuaryPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">January</h4>
            <p>
                January started a bit rough, we both got sick. But we saw snow for the first time in a long time in DC.
                I really enjoyed walking around the mall with you, and seeing all those snowmen. We started Darksiders,
                and beat that game so quick! Playing video games with you is so cozy when it's cold and cloudy out. We
                did have that warm day, and im glad we took advantage of it. I took you to Burke Lake.
            </p>
            <div className="pic-row margin-top-2x">
                {[113].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[112, 114].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Star Wars</h4>
            <p>
                We started watching Star Wars. We finished the original saga, and you're about to learn how the empire
                ruined the galaxy! You fell asleep watching episode one, you're so cute!!
            </p>
            <div className="pic-row margin-top-2x">
                {[115].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const JanuaryPage = withWrapper(JanuaryPageInner);
