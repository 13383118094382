import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const AugustPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">August</h4>
            <p>
                We went to Great Falls again and you scratched my head, i was falling for you.
            </p>
            <div className="pic-row margin-top-2x">
                {[23, 25].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[21, 22].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">We were off to Italy!</h4>
            <p>
                But we missed our flight, so we stayed a night in Philadelphia. I was impressed with how you handled
                everything and it made me admire you even more. Of course, i enjoyed spending a day exploring the city
                with you and playing around in the arcade.
            </p>
            <div className="pic-row margin-top-2x">
                {[26, 27].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">We got to Italy!!!</h4>
            <p>Our first trip out of the country together! We arrived first in Venice.</p>
            <div className="pic-row margin-top-2x">
                {[28, 29].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[30].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">I took you to the mountains</h4>
            <p>
                This has still got to be one of my favorite days with you! I was pretty nervous in the beginning,
                driving you around in a foreign country. But the travel went on just fine, and as the day continued we
                got to experience some of the most beautiful nature together.
            </p>
            <div className="pic-row margin-top-2x">
                {[31, 32].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[33, 34].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[35, 36, 37].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[38].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[39, 40].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">That second hike...</h4>
            <p>
                We climbed an entire mountain, it was so much fun!! And we took some of my favorite photos of us. They
                look unreal!
            </p>
            <div className="pic-row margin-top-2x">
                {[41].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[42].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[43].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Arriving to Florence</h4>
            <p>
                My favorite city on the trip, for many reasons. We hopped on a train to Florence and then started
                exploring right away, on the first day. We walked around plazas and you showed me the sunset on the
                bridge, i loved that.
            </p>
            <div className="pic-row margin-top-2x">
                {[44].map((num) => (
                    <PictureItem key={num} className="limit-width-250" src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">The second day in Florence we went to the coast</h4>
            <p>
                We saw the tower of Pisa, and stopped by several of the cities in Cinque Terre. My first time, and only time so far, swimming
                with you!! I love how spontaneous and easy going you are. What a wonderful time!
            </p>
            <div className="pic-row margin-top-2x">
                {[45].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[46].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[47, 48, 49].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">August 16th</h4>
            <p>
                Of course, the third day was the best. It had felt like we walked around the entire city. We started the
                day off seeing David, what a guy, walked around a massive garden, climbed a bell tower, and went to one
                of my favorite dinner dates, a local Florence dinner spot. We got some Prosecco, and roamed the streets
                past dark. And best of all, you asked me to be your boyfriend. ❤️
            </p>
            <div className="pic-row margin-top-2x">
                {[50].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[501, 51].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Rome</h4>
            <p>
                Our first day officially together. I planned a route for us to walk through and see some of the sites. You looked very handsome, as always.
            </p>
            <div className="pic-row margin-top-2x">
                {[53].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[54].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[55, 56, 57, 58].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <p>
                Our second day we saw the colosseum and those things you call rocks or rubble.
            </p>
            <p>
                Our last day we were pretty done with Rome, but continued our Harry Potter journey.
            </p>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const AugustPage = withWrapper(AugustPageInner);
