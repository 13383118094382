import "./LiamHomePage.scss";
import Container, { Sizes } from "@components/core/Container";
import React from "react";
import { useEffect } from "react";
import bg from './PageWrapperBg.jpg';

export const withWrapper = (Component: any) => (props: any) => {
    
    useEffect(() => {
        window.scroll(0,0);
    }, []);
    
    return (
        <div className="home-l" style={{backgroundImage: `url(${bg})`}}>
            <Container size={Sizes.lg}>
                <div className="home-box">
                    <Component {...props} />
                </div>
            </Container>
        </div>
    );
}
    
