import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const MayPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title text-center">I thought i'd tell our story...</h4>
            <p>
                A story of our adventures, a story of how i fell in love with you. I'll use only pictures of us, some
                words, and all of our adventures. I won't always go into my feelings, but i hope some of these pictures
                and memories will help explain the feelings i have for you and show you how in love with you i am... I was
                smitten with you from the very beginning, from one of our very first dates in May.
            </p>
            <div className="pic-row margin-top-2x pic-row-center">
                {[1].map((num) => (
                    <PictureItem key={num} className="limit-width-250" src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const MayPage = withWrapper(MayPageInner);
