import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const JunePageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">I started getting a bigger crush...</h4>
            <p>
                We went out clubbing a lot in June. Dancing with you is one of my favorite things. Kikis with you is the
                best, it'll always reminds me of you.
            </p>
            <div className="pic-row margin-top-2x">
                {[2, 3].map((num) => (
                    <PictureItem key={num} className="limit-width-320" src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Took us to our first hike in Great Falls!</h4>
            <p>You started wrapping your arms around me.</p>
            <div className="pic-row margin-top-2x">
                {[4, 5, 6].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">And you have some big arms too!</h4>
            <div className="pic-row margin-top-1x">
                {[7].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <p>
                By the end of June, i had already wanted you to be my boyfriend, and we had been exclusive for some
                time. You came down to Ashburn to see me when i was dog sitting and we went to the Air and Space Museum.
                You came down again to Ashburn and helped comfort me after my dog had passed, that meant so much to me.
            </p>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const JunePage = withWrapper(JunePageInner);
