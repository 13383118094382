import React from "react";
import { Route, Routes, useNavigate } from "react-router";

import { FirstPage } from "@components/LiamPages/FirstPage";
import { MayPage } from "@components/LiamPages/MayPage";
import { AugustPage } from "@components/LiamPages/AugustPage";
import { DecemberPage } from "@components/LiamPages/DecemberPage";
import { JulyPage } from "@components/LiamPages/JulyPage";
import { JunePage } from "@components/LiamPages/JunePage";
import { NovemberPage } from "@components/LiamPages/NovemberPage";
import { OctoberPage } from "@components/LiamPages/OctoberPage";
import { SeptemberPage } from "@components/LiamPages/SeptemberPage";
import { JanuaryPage } from "./JanuaryPage";
import { FebruaryPage } from "./FebruaryPage";
import { useEffect } from "react";

type Props = {};

const pages = [
    "00255",
    "0000FF",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
    "january",
    "february",
];

export const LiamHomePage = (props: Props) => {
    const navigate = useNavigate();

    const onContinue = (currentPage: number) => {
        navigate("/my-love/" + pages[currentPage + 1]);
    };

    useEffect(() => navigate("/my-love"), []);

    return (
        <Routes>
            <Route path="/my-love" element={<FirstPage onContinue={() => onContinue(0)} />} />
            <Route path="/my-love/0000FF" element={<MayPage onContinue={() => onContinue(1)} />} />
            <Route path="/my-love/june" element={<JunePage onContinue={() => onContinue(2)} />} />
            <Route path="/my-love/july" element={<JulyPage onContinue={() => onContinue(3)} />} />
            <Route path="/my-love/august" element={<AugustPage onContinue={() => onContinue(4)} />} />
            <Route path="/my-love/september" element={<SeptemberPage onContinue={() => onContinue(5)} />} />
            <Route path="/my-love/october" element={<OctoberPage onContinue={() => onContinue(6)} />} />
            <Route path="/my-love/november" element={<NovemberPage onContinue={() => onContinue(7)} />} />
            <Route path="/my-love/december" element={<DecemberPage onContinue={() => onContinue(8)} />} />
            <Route path="/my-love/january" element={<JanuaryPage onContinue={() => onContinue(9)} />} />
            <Route path="/my-love/february" element={<FebruaryPage />} />
        </Routes>
    );
};
