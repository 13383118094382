import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const DecemberPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">December</h4>
            <p>
                We started Sons of Forest! I'm so good at building walls, i knoww. I'll always protect you my love. We
                went on another really cute date, this time to Middleburg to see the Santa parade.
            </p>
            <div className="pic-row margin-top-2x">
                {[76].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">San Diego</h4>
            <p>
                Travelling with you is just the best. I enjoyed this trip so much babe, you can't beat sunny weather
                with your favorite person.
            </p>
            <div className="pic-row margin-top-2x">
                {[750].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[78, 79].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">The MOST handsome boyfriend</h4>
            <p>You are.</p>
            <div className="pic-row margin-top-2x">
                {[80].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">This was also one of my favorite days, unforgettable!</h4>
            <p>We went and climbed a hill, saw some nice views, and I got some good kisses.</p>
            <div className="pic-row margin-top-2x">
                {[81, 82, 83].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[85, 86].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[84].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">A beautiful sunset</h4>
            <p>
                You were rating houses once we got back in the car and then we drove towards the beach. We went to have
                an amazing lunch and see a beautiful sunset at the beach. Some really good memories with you, my love.
            </p>
            <div className="pic-row margin-top-2x">
                {[87].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[88].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Back home we go</h4>
            <p>
                Back home we go, but not for long! One of the best things i did in San Diego was convince you to come to
                Ireland, and the UK.
            </p>
            <div className="pic-row margin-top-2x">
                {[89, 890].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Ireland</h4>
            <p>
                Spending the holidays with you meant so much to me. It's only natural i'd want to spend holidays with
                the person i love the most. It also makes the family time more fun, and really I want you to be my
                family. I have a good family, all things considered, but we do have our fair share of problems and
                having the person i love be there with me just makes it so much more enjoyable. I am way more
                comfortable when i have you next to me. Thank you so much for spending Christmas with my family and i!!
            </p>
            <h4 className="title">Dunmore East</h4>
            <p>You gotta admit, the next day and our trip to Dunmore East was pretty amazing.</p>
            <div className="pic-row margin-top-2x">
                {[90, 91, 95, 94].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[92].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[96, 97, 93].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Scotland</h4>
            <p>
                Another incredible day with you!!! Running around Scotland, the Royal Mile, and Arthur's Seat. You make
                me so incredibly happy ❤️
            </p>
            <div className="pic-row margin-top-2x">
                {[100].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[99, 940, 98].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Off to London we go</h4>
            <div className="pic-row margin-top-1x">
                {[101, 102].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">And the last day of 2023!</h4>
            <p>
                An amazing day to end off the year. Really im just very lucky to have spent it with you, thats all i
                could want. We walked around a lot (even though a lot of the streets were closed), we went to a park,
                got rained on a bit, and we went to the Harry Potter store at the train station. My little raven claw.
                We walked up Primrose Hill, and had some nice Mexican food in a bit of a sketchy place (my bad). I got
                tipsy with my man and kissed him in the new year, it was perfect.
            </p>
            <div className="pic-row margin-top-2x">
                {[103, 104].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[105, 106, 107].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[108].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">On to 2024 and new adventures!</h4>
            <p>Lots of fun being had, walking and tubing around London all the way to Tower Bridge!</p>
            <div className="pic-row margin-top-2x">
                {[109, 110].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[111].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const DecemberPage = withWrapper(DecemberPageInner);
