const domain = () => {
    if (LOCALSTACK === "local") {
        return "http://localhost/api/"
    } else if (IS_PRODUCTION) {
        return "https://stamperz.com/api/"
    } else {
        return "http://localhost:9000/api/"
    }
}

export const config = {
    API_DOMAIN: domain(), 
};
  