import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const SeptemberPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">We arrived back home</h4>
            <p>
                I had felt like i had gotten so close to you and connected so deeply with you on our trip to Italy. I
                went out and wanted to show you how much you meant to me, i bought you the biggest and best bouquet that i've ever found.
                This is the only picture of just you that i will include. I love you!!!
            </p>
            <div className="pic-row pic-row-center margin-top-2x">
                <PictureItem src={`images-l/pic - L.jpeg`} />
            </div>
            <h4 className="title">I took you to Shenandoah</h4>
            <p>
                See, we have beautiful nature close to us too! At this point, i had already known that i loved you. It
                would take me a bit longer to say it.
            </p>
            <div className="pic-row margin-top-2x">
                {[59].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="pic-row">
                {[60, 61, 62].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <p>
                The next day, you met my parents for the first time! We did Top Golf and like usual I was super nervous
                for no reason. It went off so well, and my parents seemed to like you so thats a bonus. Ofcourse, my mom
                loves you now. Introducing you to my family was a big step, and it made me fall deeper in love with you.
            </p>
            <h4 className="title">We went to Capitol Hill</h4>
            <p>
                I love all the walks we do, down to Longbridge, and around your buildings. It's quality time. Course we walk
                much less around my place since im always at yours. So have to remember the times we do.
            </p>
            <div className="pic-row margin-top-2x">
                {[63].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const SeptemberPage = withWrapper(SeptemberPageInner);
