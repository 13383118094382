import './Container.scss';
import classnames from 'classnames';
import React from 'react';

export enum Sizes {
    lg = "lg",
    md = "md",
    sm = "sm",
    xs = "xs",
    xxs = "xxs",
}

type Props = {
    size: Sizes;
    className?: string;
    children: React.ReactNode;
}

const Container = (props: Props) => (
    <div className={classnames("container", props.className, `container--${props.size}`)}>
        {props.children}
    </div>
);

export default Container;