import { AppState, useAppStore } from "@components/App";
import { PictureItem } from "@components/HomePage/PictureItem";
import React, { useEffect, useState } from "react";
import { withWrapper } from "./PagesWrapper";
import { ApiResponseBody, HttpBaseHeaders, HttpMethod } from "@components/configApi";
import { config } from "@components/config";
import Loader from "@components/core/Loader";

type Props = {};

export const FebruaryPageInner = (props: Props) => {
    const checked = useAppStore((state: AppState) => state.liamChecked);
    const setChecked = useAppStore((state: AppState) => state.setLiamChecked);

    const onChange = () => setChecked(!checked);

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const [checkValentineResp, setCheckValentineResp] = useState(false);
    const [response, setResponse] = useState("");
    const [errorResponse, setErrorResponse] = useState("");

    useEffect(() => {
        setIsLoading(true);

        async function fetchCheckValentine() {
            const resp = await fetch(config.API_DOMAIN + "my-love/check-valentine", {
                method: HttpMethod.GET,
                headers: HttpBaseHeaders,
                credentials: "include",
            });

            if (resp.status == 200) {
                setChecked(true);
                setCheckValentineResp(true);
            }

            setIsLoading(false);
        }

        fetchCheckValentine();
    }, []);

    const onSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setFormError("");

        if (!checked) {
            setFormError("At least one checkobox must be selected");
            setIsLoading(false);
        }

        if (checked) {
            const resp = await fetch(config.API_DOMAIN + "my-love/save-valentine", {
                method: HttpMethod.POST,
                headers: HttpBaseHeaders,
                body: JSON.stringify({}),
                credentials: "include",
            });

            setIsLoading(false);
            const responseData: ApiResponseBody = await resp.json();

            if (resp.status == 200) {
                setResponse(responseData.data.username);
            } else if (resp.status == 401) {
                // not authenticated
            } else if (responseData.error) {
                setErrorResponse(responseData.error.message);
            }
        }
    };

    return (
        <>
            <h4 className="title">February</h4>
            <p>All this perhaps to just ask one question...</p>
            <div className="pic-row margin-top-2x pic-row-center">
                {[116].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title margin-bottom-1x">Will you be my valentine?</h4>
            <form onSubmit={onSubmit}>
                {!(response || checkValentineResp) && (
                    <>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id="yes" checked={checked} onChange={onChange} />
                            <label htmlFor="yes" className="check-box" />
                            <span className="checkbox-text">Yes</span>
                        </div>
                        <div className="checkbox-wrapper margin-top-1x">
                            <input type="checkbox" id="no" checked={false} onChange={onChange} />
                            <label htmlFor="no" className="check-box" />
                            <span className="checkbox-text">No</span>
                        </div>
                    </>
                )}
                {formError && <p className="error-text">{formError}</p>}
                {!(response || checkValentineResp) && (
                    <div className="button-flex">
                        <button type="submit" className="button-submit" disabled={isLoading}>
                            {!isLoading ? "Submit" : <Loader className="spinner" />}
                        </button>
                    </div>
                )}
                {(response || checkValentineResp) && (
                    <>
                        <p>You said yes! I'm so lucky!!!</p>
                        <p>I have the best boyfriend in the world ❤️❤️❤️</p>
                        <p>Decisions are final, no refunds or returns.</p>
                    </>
                    
                )}
                {errorResponse && <p className="error-text">{errorResponse}</p>}
            </form>
        </>
    );
};

export const FebruaryPage = withWrapper(FebruaryPageInner);
