import { config } from "@components/config";
import { HttpMethod, HttpBaseHeaders, ApiResponseBody } from "@components/configApi";
import Container, { Sizes } from "@components/core/Container";
import Loader from "@components/core/Loader";
import React, { useState } from "react"
import { useLocation } from "react-router-dom";

type Props = {
    onContinue: (path: string) => void;
}

export const FirstPage = (props: Props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState("");

    const location = useLocation();

    const onSubmit = async () => {
        setIsLoading(true);

        const resp = await fetch(config.API_DOMAIN + "my-love/is-true", {
            method: HttpMethod.GET,
            headers: HttpBaseHeaders,
            credentials: 'include'
        })

        setIsLoading(false);

        if (resp.status == 200) {
            props.onContinue(location.pathname)
        } else if (resp.status == 401) {
            //not authenticated
        } else {
            const body = resp.body as ApiResponseBody;
            if (!!body.error) {
                setResponse(body.error.message);
            }
        }
    }

    return (
        <div className="home-main">
        <div id="stars"/>
        <div id="stars2"/>
        <div id="stars3" />
        <Container size={Sizes.sm}>
        <h2 id="title" className="title">Hello my love bug,</h2>
            <h4 className="title">
                I thought i'd tell a story.
            </h4>
            <button type="submit" className="button-submit" disabled={isLoading} onClick={onSubmit}>
                {!isLoading ? "Continue" : <Loader className="spinner" />}
            </button>
            {response && <p>You're not my love</p>}
        </Container>
        <div/>
    </div>
    )
}