import "./App.scss";

import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import { Login } from "./auth/LoginPage/Login";
import { Home } from "./HomePage/Home";

import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { RegisterAccount } from "./auth/RegisterAccountPage/RegisterAccount";

export type AppState = {
    username: string;
    setUsername: (username: string) => void;
    liamChecked: boolean;
    setLiamChecked: (checked: boolean) => void;
};

export const useAppStore = create<AppState>()(
    persist(
        (set, _get) => ({
            username: "",
            setUsername: (username: string) => set({ username: username }),
            liamChecked: false,
            setLiamChecked: (checked: boolean) => set({ liamChecked: checked }),
        }),
        {
            name: "app-store",
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
);

export const App = () => {
    const navigate = useNavigate();

    const username = useAppStore((state: AppState) => state.username);
    const setUsername = useAppStore((state: AppState) => state.setUsername);

    const login = (username: string) => {
        setUsername(username);
        navigate("/");
    };

    const onRegisterClick = () => navigate("/register");

    return (
        <div className="App">
            <Routes>
                <Route path="/register" element={<RegisterAccount login={login} />} />
                <Route path="/authenticate" element={<Login login={login} onRegisterClick={onRegisterClick} />} />
                <Route path="*" element={<Home username={username} />} />
            </Routes>
        </div>
    );
};
