import "./Home.scss";

import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Container, { Sizes } from "@components/core/Container";
import { LiamHomePage } from "@components/LiamPages/LiamHomePage";

type Props = {
    username: string;
};

export const Home = (props: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.username) navigate("/authenticate");
       
    }, []);

    if (props.username == "my_love") {
        return <LiamHomePage />
    } else {
        return (
            <div className="home-main">
                <div id="stars"/>
                <div id="stars2"/>
                <div id="stars3" />
                <Container size={Sizes.lg}>
                        Hello {props.username}
                </Container>
                <div/>
            </div>
        );
    }
};
