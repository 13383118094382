export const HttpBaseHeaders = { 
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export type ApiResponseBody =  {
    data?: any;
    error?: {
        code: number;
        message: string;
    }
}