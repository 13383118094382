import { PictureItem } from "@components/HomePage/PictureItem";
import React from "react";
import { withWrapper } from "./PagesWrapper";

type Props = {
    onContinue: () => void;
};

const OctoberPageInner = (props: Props) => {
    return (
        <>
            <h4 className="title">October</h4>
            <p>
                We went to see Kim Petras. We went to our first festival together! We saw Carly Rae Jepsen, love her.
            </p>
            <div className="pic-row margin-top-2x">
                {[603, 64].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Did some fall things</h4>
            <p>The fall garden tour at the White House.</p>
            <div className="pic-row margin-top-2x">
                {[65, 66].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <h4 className="title">Pumpkins and corn</h4>
            <p>We picked some pumpkins and wandered around corn mazes.</p>
            <div className="pic-row margin-top-2x">
                {[67].map((num) => (
                    <PictureItem key={num} src={`images-l/pic - ${num}.jpeg`} />
                ))}
            </div>
            <p>
                The next day i would take you back to Ashburn, and we carved the pumpkins together with my little
                sister. That was very special to me.
            </p>
            <div className="button-flex">
                <button type="submit" className="button-submit" onClick={props.onContinue}>
                    Continue
                </button>
            </div>
        </>
    );
};

export const OctoberPage = withWrapper(OctoberPageInner);
