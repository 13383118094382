import "./PictureItem.scss";

import React, { ReactEventHandler, SyntheticEvent } from "react";
import { useState } from "react";
import Image from "@components/core/Image"

type Dimensions = {
    width: number;
    height: number;
}

type Props = {
    src: string;
    className?: string;
}

export const PictureItem = (props: Props) => {

    const [dimensions, setDimensions] = useState<Dimensions | null>(null);

    const onImgLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        const img = e.target as HTMLImageElement;
        setDimensions({ height: img.offsetHeight, width: img.offsetWidth});
    }

    let aspect = dimensions ? parseFloat((dimensions.width / dimensions.height).toFixed(4)) : 1;

    return (
        <div className={props.className} style={{ flex: aspect }}>
            <Image fileName={props.src} onLoad={(e) => onImgLoad(e)} alt={props.src}/>
        </div>
    );
};